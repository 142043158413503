import { Menu } from 'antd';
import '../CSS/HeaderMenu.css';
import image from '../Images/Logo.png'
import { Link } from "react-router-dom";

export default function Example()
{
    return (
    <>
    <div className="menu-container">
        <div className="menu-logo"> 
            <img className="menu-logo-image" alt='' src={image}/>
            <span className='menu-logo-text'>Florida Best Getaways</span>
        </div>
        <div className='menu-items'>            
        <Menu mode="horizontal" defaultSelectedKeys={['/home']}>
            <Menu.Item key={"/home"}>
                <Link to='/home'>HOME</Link>
            </Menu.Item>
            <Menu.Item key={"about"}>
            <Link to='/about'>ABOUT US</Link>
            </Menu.Item>            
            <Menu.Item key={"signin"} onClick={SignInOnClick}>
            <Link to='/signin'>SIGN IN</Link>
            </Menu.Item>
            <Menu.Item key={"register"} onClick={RegisterOnClick}>
            <Link to='/register'>REGISTER</Link>
            </Menu.Item>
            </Menu>
            
        </div>
    </div>
    </>
    )
};

const SignInOnClick = () => {
    // Lógica para el evento onClick del elemento "ABOUT US"
    console.log("ABOUT US clicked");
};

const RegisterOnClick = () => {
    // Lógica para el evento onClick del elemento "ABOUT US"
    console.log("ABOUT US clicked");
};
