import React, { useRef, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const useIsVisible = (ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isVisible;
};

export const AnimatedDiv = ({ children, className, style, duration = 0.75, delay = 0.25, direction = 'y', position = 70 }) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isVisible) {
      mainControls.start('visible');
    }
  }, [isVisible, mainControls]);

  return (
    <motion.div
    style = {style}
    className = {className}
    variants={{
      hidden:  { opacity: 0, [direction]: position },
      visible: { opacity: 1, [direction]: 0 },
    }}
    initial="hidden"
    animate={mainControls}
    transition={{ duration: duration, delay: delay }}
    ref={ref}
    >
      {children}
    </motion.div>
  );
};