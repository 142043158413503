import React, { useEffect, useRef } from 'react';
import  { AnimatedDiv } from './AnimatedDiv'
import '../CSS/HeaderSection.css';

export default function HeaderSection() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const images = carousel.querySelectorAll('.header-image');

    let currentIndex = 1;

    function showImage(index) {
      images.forEach((image, i) => {
        if (i === index) {
          image.classList.add('active');
          image.classList.add('animate');
        } else {
          image.classList.remove('active');
        }
      });
    }

    function nextImage() {
      currentIndex = (currentIndex + 1) % images.length;
      showImage(currentIndex);
    }

    carousel.addEventListener('transitionend', () => {
        images.forEach((image, i) => {
            if (i === currentIndex) {
              image.classList.add('active');
              image.classList.add('animate');
            } else {
              image.classList.remove('active');
              image.classList.remove('animate');
            }
          });
    });

    const intervalId = setInterval(nextImage, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
  <>
    <div className="header-container">
      <div className="carousel bg-container" ref={carouselRef}>
        <div className="header-image header-image-1 active animate"></div>
        <div className="header-image header-image-2"></div>
        <div className="header-image header-image-3"></div>
        <div className="header-image header-image-4"></div>
      </div>
      <AnimatedDiv className="header-info">
          Experiencia inigualable con nosotros
      </AnimatedDiv>
      <AnimatedDiv className="header-title">
          Florida Best Getaways
      </AnimatedDiv>
    </div>

  </>
  );
}