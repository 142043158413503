import '../App.css';
import HouseCard from '../Components/HouseCard';
import  { AnimatedDiv } from './AnimatedDiv'

import House1 from '../Images/Houses/1/1.jpg'
import House2 from '../Images/Houses/2/1.jpg'
import House3 from '../Images/Houses/3/1.jpg'

export default function Example()
{
    return (
    <>
        <AnimatedDiv className='room-title'>
            Rooms & Apartments
        </AnimatedDiv>
        <AnimatedDiv className='room-details' delay={0.35} >
            Find Your Rooms, for your abaility
        </AnimatedDiv>

        <AnimatedDiv className="grid-container"  style={{ padding: '0 48px' }}>
            <HouseCard title={"Casita mi Encanto"} ubication={"240 Old Mill Circle Kissimmee, FL"} bedrooms={4} bathrooms={3} price={197} image={House1} rate={4} />
            <HouseCard title={"Tranquility Villa"} ubication={"2638 Tranquility Way Kissimmee, FL"} bedrooms={4} bathrooms={4} price={187} image={House2} rate={5}/>
            <HouseCard title={"Beach Paradise"} ubication={"3505 S Ocean Dr #618, Hollywood, FL"} bedrooms={1} bathrooms={"1 1/2"} price={591} image={House3} rate={3.5} />
        </AnimatedDiv>
    </>

    )
};