import './App.css';
import FooterSection from './Components/FooterSection';
import HeaderMenu from './Components/HeaderMenu';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Components/Home';
import Empty from './Components/Empty';
import Register from './Components/Register';
import SignIn from './Components/SignIn';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as React from 'react'
import { Layout} from 'antd';

const { Content } = Layout;

export default function App() {
  return (
      <>
      <Router>
    
      <HeaderMenu />

      <Content>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<Empty />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Content>

      <FooterSection />

      </Router>
      </>
  );
}

