import '../CSS/HouseArt.css';
import  { AnimatedDiv } from './AnimatedDiv'

export default function Example()
{
    return (
    <>
        <div className="art-container">
        <div className="art-background"></div>
        <AnimatedDiv className="art-image" direction='x' position={-100}>
            <div className="art-image-mark">
                <p className="art-image-mark-text">MODERN</p>
            </div>
        </AnimatedDiv>
        <AnimatedDiv className="art-card"  direction='x' position={100}>
            <p className='art-card-title' >PROPIEDADES DE LUJO</p>
            <p className='art-card-info' >Contamos con propiedades verdaderamente confortables</p>
        </AnimatedDiv>
        </div>
    </>

    )
};