import HouseDetails from '../Components/HouseDetails'
import HouseArt from '../Components/HouseArt'
import HeaderSection from '../Components/HeaderSection';
import * as React from 'react'

export default function Home ()
{
    return (
    <>
        <HeaderSection />
        <HouseDetails />
        <HouseArt />
    </>
    )
};